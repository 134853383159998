<template>
  <div class="page">
    <div>
      <!-- 左侧侧边栏 -->
      <sidebarUserInfo></sidebarUserInfo>
    </div>
    <div>
      <btnTopBar
        :topList="topList"
        :moren="moren"
        v-if="topList.length > 0"
      ></btnTopBar>
      <div class="thetopbox">
        <el-form ref="form" :model="queryParams" label-width="100px">
          <el-row style="margin-top: 10px">
            <el-col :span="4">
              <el-form-item :label="$t('N_I_124')" prop="memberCode">
                <el-input
                  clearable
                  v-model="queryParams.memberCode"
                ></el-input></el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item :label="$t('N_I_242')" prop="memberSettlePeriodId">
                <el-select
                  clearable
                  :placeholder="$t('CK_KS_38')"
                  v-model="queryParams.memberSettlePeriodId"
                >
                  <el-option
                    v-for="item in memberSettlePeriodList"
                    :key="item.pkId"
                    :label="item.settleDate"
                    :value="item.pkId"
                  ></el-option> </el-select></el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item :label="$t('w_0166')" prop="level">
                <el-input
                  clearable
                  v-model="queryParams.level"
                ></el-input></el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item :label="$t('w_0167')" prop="cantz">
                <el-select clearable :placeholder="$t('CK_KS_38')" v-model="cantz">
                  <el-option
                    v-for="(item, index) in tzList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select> </el-form-item
            ></el-col>
            <el-col :span="4" style="margin-left: 30px">
              <div class="searchbox">
                <el-button class="searchbtn" @click="getSearch">{{ $t('N_I_183') }}</el-button>
                <el-button @click="reChongzhi">{{ $t('CK_KS_6') }}</el-button>
                <el-button @click="goback">{{$t('N_I_241')}}</el-button>
              </div>
            </el-col>
          </el-row></el-form
        >
      </div>

      <div class="theorgtree">
        <div class="tree">
          <div class="tree-content" @mousedown.stop="move" @wheel="handleWheel">
            <div :style="{ transform: `scale(${scale})` }">
              <vue2-org-tree
                :data="data"
                collapsable
                ref="orgTree"
                @on-expand="onExpand"
                @on-node-click="NodeClick"
                @on-node-mouseover="onMouseover"
                @on-node-mouseout="onMouseout"
                :renderContent="renderContent"
                v-bind="$listeners"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="rendercontent"
      ref="htmlContent"
      style="
        width: 516px;
        background-color: #ffffff;
        display: none;
        height: 470px;
      "
    >
      <div class="toprender">
        <div class="leftimg">
          <img
            class="img1"
            :src="'data:image/png;base64,' + popdata.avatarUrlBase64"
          />
          <img
            class="img2"
            :src="'data:image/png;base64,' + popdata.countryUrl2Base64"
          />
        </div>
        <div class="centerbox">
          <div class="lineboex">
            <div class="linetitle">{{ $t('N_I_124') }}</div>
            <div class="linecontent">{{ popdata.memberCode }}</div>
          </div>
          <div class="lineboex">
            <div class="linetitle">{{$t('CK_KS_14')}}</div>
            <div class="linecontent">{{ popdata.name }}</div>
          </div>
          <div class="lineboex">
            <div class="linetitle">{{$t('w_0354')}}</div>
            <div class="linecontent">{{ popdata.level }}</div>
          </div>
          <div class="lineboex">
            <div class="linetitle">{{$t('MY_ORD_3')}}</div>
            <div class="linecontent">{{ popdata.payDate }}</div>
          </div>
        </div>
        <div class="rightimg">
          <img
            :src="'data:image/png;base64,' + popdata.settleCountryUrl2Base64"
          />
          <div>{{$t('PER_DA_4')}}</div>
        </div>
      </div>
      <div class="bottomrender">
        <div class="flexbox">
          <div class="thetitle">{{$t('w_0355')}}</div>
          <div class="linecontent">{{$t('N_I_106')}}</div>
          <div class="linecontent">{{$t('ENU_TOTAL_V_1')}}(PV)</div>
        </div>
        <div class="flexbox">
          <div class="thetitle">{{$t('w_0356')}}</div>
          <div class="linecontent">{{ popdata.directPushNumber }}</div>
          <div class="linecontent">{{ popdata.newPerformancePv }}</div>
        </div>
        <div class="flexbox">
          <div class="thetitle">{{$t('N_I_118')}}</div>
          <div class="linecontent">{{ popdata.teamNumber }}</div>
          <div class="linecontent">{{ popdata.teamHistoryPerformancePv }}</div>
        </div>
      </div>
    </div>
    <div class="copyContent" ref="copyContent" v-show="false">
      <div>{{$t('N_I_124')}}：{{ popdata.memberCode }}</div>
      <div>{{$t('CK_KS_14')}}：{{ popdata.name }}</div>
      <div>{{$t('w_0354')}}：{{ popdata.level }}</div>
      <div>{{$t('MY_ORD_3')}}：{{ popdata.payDate }}</div>
      <div>{{$t('w_0355')}}&nbsp;&nbsp;{{$t('w_0356')}}&nbsp;&nbsp;{{$t('N_I_118')}}</div>
      <div>{{$t('N_I_106')}}&nbsp;&nbsp;{{ popdata.directPushNumber }}&nbsp;&nbsp;{{
          popdata.directPushNumber
        }}
      </div>
      <div>{{$t('ENU_TOTAL_V_1')}}(PV)&nbsp;&nbsp;{{ popdata.teamNumber }}&nbsp;&nbsp;{{
          popdata.teamHistoryPerformancePv
        }}
      </div>
    </div>
  </div>
</template>
      
<script>
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";
import btnTopBar from "@/components/btnTopBar.vue";
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import {
  getTjFramework,
  getMemberSettlePeriod,
  getUrlBase,
} from "@/api/archityecture";
export default {
  components: {
    btnTopBar,
    sidebarUserInfo,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      moren: "tjjg2",
      topList: [
        {
          name:  this.$t('w_0175'),
          path: "recommend",
        },
        {
          name:  this.$t('w_0176'),
          path: "tjjg2",
        },
        {
          name:  this.$t('w_0177'),
          path: "tjjg3",
        },
        {
          name:  this.$t('w_0178'),
          path: "tjjg4",
        },
      ],
      queryParams: {
        memberSettlePeriodId: "", //期数
        memberCode: "", //会员编号
        level: "", //代数
      },
      basicSwitch: false,
      basicInfo: { id: null, label: null },
      data: {},
      memberSettlePeriodList: [],
      popdata: {},
      tzList: [
        { value: 0, label: this.$t('EDEALER_YES_NO_0') },
        { value: 1, label: this.$t('EDEALER_YES_NO_1') },
      ],
      cantz: 1, //0不可拖拽,1可拖拽
      scale: 1,
    };
  },
  created() {
    this.queryParams.memberCode = this.userInfo.memberCode;
    this.getSearch();
    this.getAvarerInfo();
  },
  methods: {
    handleWheel(event) {
      if (this.cantz == 1) {
        event.preventDefault();
        const delta = Math.sign(event.deltaY);
        const newScale = this.scale - delta * 0.1;
        this.scale = Math.max(0.01, Math.min(newScale, 10));
      }
    },
    //拖拽移动
    move(e) {
      if (this.cantz == 0) {
        return;
      }
      const odiv = e.currentTarget; // 获取元素

      // 算出鼠标相对元素的位置
      const disX = e.clientX - odiv.offsetLeft;
      const disY = e.clientY - odiv.offsetTop;
      document.onmousemove = (e) => {
        // 鼠标按下并移动的事件
        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        const left = e.clientX - disX;
        const top = e.clientY - disY;

        // 绑定元素位置到positionX和positionY上面
        this.positionX = top;
        this.positionY = left;

        // 移动当前元素
        odiv.style.left = left + "px";
        odiv.style.top = top + "px";
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    getAvarerInfo() {
      getMemberSettlePeriod().then((res) => {
        this.memberSettlePeriodList = res.data;
        this.memberSettlePeriodList.forEach((ele) => {
          if (ele.isThisDay == 0) {
            this.queryParams.memberSettlePeriodId = ele.pkId;
          }
        });
      });
    },
    //返回
    goback() {
      this.queryParams.memberCode = this.data.parentMemberCode;
      this.getSearch();
    },
    //鼠标移入
    onMouseover(e, data) {
      this.basicInfo = data;
      this.basicSwitch = true;
    },
    //鼠标移出
    onMouseout(e, data) {
      this.basicSwitch = false;
    },
    //渲染节点
    renderContent(h, data) {
      return (
        <div class="rendercontent">
          <div class="toprender">
            <div class="leftimg">
              <img class="img1" src={data.avatarUrl}></img>
              <img class="img2" src={data.countryUrl}></img>
            </div>
            <div class="centerbox">
              <div class="lineboex">
                <div class="linetitle">{ this.$t('N_I_124') }</div>
                <div class="linecontent">{data.memberCode}</div>
              </div>
              <div class="lineboex">
                <div class="linetitle">{this.$t('CK_KS_14')}</div>
                <div class="linecontent">{data.name}</div>
              </div>
              <div class="lineboex">
                <div class="linetitle">{this.$t('w_0354')}</div>
                <div class="linecontent">{data.level}</div>
              </div>
              <div class="lineboex">
                <div class="linetitle">{this.$t('MY_ORD_3')}</div>
                <div class="linecontent">{data.payDate}</div>
              </div>
            </div>
            <div class="rightimg">
              <img src={data.settleCountryUrl2}></img>
              <div>{this.$t('PER_DA_4')}</div>
            </div>
          </div>
          <div class="bottomrender">
            <div class="flexbox">
              <div class="thetitle">{this.$t('w_0355')}</div>
              <div class="linecontent">{this.$t('N_I_106')}</div>
              <div class="linecontent">
                { this.$t('ENU_TOTAL_V_1') }(PV)</div>
            </div>
            <div class="flexbox">
              <div class="thetitle">{this.$t('w_0356')}</div>
              <div class="linecontent">{data.directPushNumber}</div>
              <div class="linecontent">{data.newPerformancePv}</div>
            </div>
            <div class="flexbox">
              <div class="thetitle">{this.$t('N_I_118')}</div>
              <div class="linecontent">{data.teamNumber}</div>
              <div class="linecontent">{data.teamHistoryPerformancePv}</div>
            </div>
          </div>
          <div class="footerbox">
            <div
              class="footerbtn btn1"
              on-click={() => {
                this.downloadImage(data);
              }}
            >
              {this.$t('S_C_45')}
            </div>
            <div
              class="footerbtn btn2"
              on-click={() => {
                this.copyText(data);
              }}
            >
              { this.$t('N_I_243') }
            </div>
            <div class="footerbtn btn3" on-click={() => this.goTop(data)}>
              {this.$t('N_I_245')}
            </div>
          </div>
        </div>
      );
    },
    copyText(data) {
      this.popdata = data;
      setTimeout(() => {
        const copyContent = this.$refs.copyContent;
        const textLines = Array.from(copyContent.querySelectorAll("div")).map(
          (div) => div.textContent.trim()
        );
        const text = textLines.join("\n");
        const tempInput = document.createElement("textarea");
        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        try {
          document.execCommand("copy");
          this.$message({
            message: this.$t('MY_CK_29'),
            type: "success",
          });
        } catch (error) {
          this.$message({
            message: this.$t('w_0349'),
            type: "warning",
          });
        }
        tempInput.remove();
        window.getSelection().removeAllRanges();
      }, 50);
    },
    async downloadImage(data) {
      await getUrlBase({
        countryUrl2: data.countryUrl2,
        settleCountryUrl2: data.settleCountryUrl2,
        avatarUrl: data.avatarUrl,
      }).then((res) => {
        this.popdata = data;
        this.popdata.countryUrl2Base64 = res.countryUrl2Base64;
        this.popdata.settleCountryUrl2Base64 = res.settleCountryUrl2Base64;
        this.popdata.avatarUrlBase64 = res.avatarUrlBase64;
      });
      const element = this.$refs.htmlContent;
      await new Promise((resolve) => setTimeout(resolve, 50)); // 根据实际情况调整延迟时间
      element.style.display = "block";
      
      // 使用html2canvas将节点生成为图片
      const canvas = await html2canvas(element);
      const image = canvas.toDataURL("image/png");
      // 复制图片到剪贴板
      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            "image/png": await new Promise((resolve) =>
              canvas.toBlob(resolve, "image/png")
            ),
          }),
        ]);
        this.$message({
          message: this.$t('MY_CK_29'),
          type: "success",
        });
      } catch (error) {
        // this.$message({
        //   message: this.$t('w_0349'),
        //   type: "warning",
        // });
      }
      // {{$t('S_C_45')}}到本地
      const link = document.createElement("a");
      link.href = image;
      link.download = "image.png";
      link.click();
      // 隐藏图片
      element.style.display = "none";
    },
    //置顶
    goTop(row) {
      this.queryParams.memberCode = row.memberCode;
      this.getSearch();
    },
    //点击节点
    NodeClick(e, data) {},
    //默认展开
    toggleExpand(data, val) {
      if (Array.isArray(data)) {
        data.forEach((item) => {
          this.$set(item, "expand", val);
          if (item.children) {
            this.toggleExpand(item.children, val);
          }
        });
      } else {
        this.$set(data, "expand", val);
        if (data.children) {
          this.toggleExpand(data.children, val);
        }
      }
    },
    collapse(list) {
      list.forEach((child) => {
        if (child.expand) {
          child.expand = false;
        }
        child.children && this.collapse(child.children);
      });
    },
    //展开
    onExpand(e, data) {
      if ("expand" in data) {
        data.expand = !data.expand;
        if (!data.expand && data.children) {
          this.collapse(data.children);
        }
      } else {
        this.$set(data, "expand", true);
      }
    },
    getSearch() {
      getTjFramework(this.queryParams).then((res) => {
        res.data.forEach((ele) => {
          ele.countryUrl2Base64 = "";
          ele.settleCountryUrl2Base64 = "";
          ele.avatarUrlBase64 = "";
        });
        this.data = res.data[0];
        this.toggleExpand(this.data, true);
      });
    },
    reChongzhi() {
      this.queryParams = {
        memberSettlePeriodId: "", //期数
        memberCode: "", //会员编号
        level: "", //代数
      };
      this.getSearch();
    },
  },
};
</script>
    
  <style lang="scss" scoped>
::v-deep .tree-content {
  background: rgba(0, 0, 0, 0) !important;
}
.tree {
  background: #fff;
  // min-height: 100vh;
  min-height: calc(100vh - 110px);
  position: relative;
  text-align: center;
  // overflow-x: scroll;
  width: 100%;
  height: 100%;
  margin: auto;
  &-content {
    text-align: center;
    width: 100%;
    height: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    // overflow: auto;
  }
}

::v-deep .org-tree-node-label .org-tree-node-label-inner {
  padding: 0;
}
::v-deep .org-tree-container {
  display: block;
  background: rgba(1, 1, 1, 0) !important;
}
::v-deep .rendercontent {
  .toprender {
    display: flex;
    border-bottom: 1px solid #cccccc;
    .leftimg {
      flex: 1;
      border-right: 1px solid #cccccc;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      .img1 {
        width: 75px;
        height: 75px;
        border-radius: 50%;
      }
      .img2 {
        margin-top: 10px;
        width: 50px;
        height: 30px;
        border-radius: 8px;
      }
    }
    .centerbox {
      padding: 10px;
      .lineboex {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .linetitle {
          margin-right: 5px;
          min-width: 70px;
          font-size: 10px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
        }
        .linecontent {
          font-size: 10px;
          font-family: MicrosoftYaHei;
          color: #333333;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 0;
          width: 160px;
          //   height: 20px;
          background: rgba(216, 216, 216, 0.3);
          border-radius: 4px;
          border: 1px solid #cccccc;
        }
      }
    }
    .rightimg {
      flex: 1;
      justify-content: center;
      align-items: center;
      padding: 10px;
      font-size: 10px;
      display: flex;
      flex-direction: column;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      text-align: center;
      img {
        width: 75px;
        height: 50px;
        border-radius: 8px;
        margin-bottom: 10px;
      }
    }
  }
  .bottomrender {
    padding: 10px;
    display: flex;
    .flexbox {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .thetitle {
        font-size: 10px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #333333;
      }
      .linecontent {
        margin-top: 5px;
        padding: 5px 0;
        font-size: 10px;
        font-family: MicrosoftYaHei;
        color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        // height: 20px;
        background: rgba(216, 216, 216, 0.3);
        border-radius: 4px;
        border: 1px solid #cccccc;
      }
    }
  }
  .footerbox {
    display: flex;
    padding: 10px;
    justify-content: space-around;
    .footerbtn {
      width: 78px;
      //   height: 28px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      padding: 6px;
      cursor: pointer;
    }
    .btn1 {
      background: #ee1e26;
    }
    .btn2 {
      background: #f37825;
    }
    .btn3 {
      background: #21c8f4;
    }
  }
}
.page {
  padding: 10px 0px;
  display: flex;
  background: #f9f9f9;
  font-size: 14px;
  .thetopbox {
    width: 1460px;
    padding: 10px;
    background: #ffffff;
    border-radius: 8px;
    .searchbox {
      display: flex;
      align-items: center;
      .searchtitle {
        margin-right: 10px;
      }
      .searchbtn {
        background: #c8161d;
        color: #ffffff;
      }
    }
  }
  .theorgtree {
    // overflow: auto;
    margin-top: 20px;
  }
}
</style>